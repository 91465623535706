import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/diseasesList',
    name: 'diseasesList',
    component: () => import('@/views/diseases/Diseases.vue')
  },
  {
    path: '/diseasesList/add',
    name: 'diseasesAdd',
    component: () => import('@/views/diseases/DiseasesAdd.vue')
  },
  {
    path: '/diseasesList/update:id',
    name: 'diseasesUpdate',
    component: () => import('@/views/diseases/DiseasesAdd.vue')
  },
  {
    path: '/diseasesList/detail:id',
    name: 'diseasesDetail',
    component: () => import('@/views/diseases/DiseasesDetail.vue')
  }
]
export default router
