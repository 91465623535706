import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/questionList',
    name: 'questionList',
    component: () => import('@/views/question/Question.vue')
  },
  {
    path: '/questionList/add',
    name: 'questionAdd',
    component: () => import('@/views/question/QuestionAdd.vue')
  },
  {
    path: '/questionList/update:id',
    name: 'questionUpdate',
    component: () => import('@/views/question/QuestionAdd.vue')
  },
  {
    path: '/questionList/detail:id',
    name: 'questionDetail',
    component: () => import('@/views/question/QuestionDetail.vue')
  }
]

export default router
