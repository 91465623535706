// 角色
const url = '/crud/iparkAdminRole/'
export interface Apis {
  /** 新增角色 */
  roleAdd: string;
  /** 修改角色 */
  roleUpdate: string;
  /** 删除角色 */
  roleDelete: string;
  /** 查询角色详情 */
  roleDetail: string;
  /** 查询菜单候选下拉 */
  roleMenu: string;
  /** 分页查询角色列表 */
  rolePage: string;
  /** 不分页查询角色列表 */
  selectIparkAdminRoleByList: string;
}

export const apis: Apis = {
  roleAdd: url + 'insertIparkAdminRole',
  roleUpdate: url + 'updateIparkAdminRole',
  roleDelete: url + 'deleteIparkAdminRole',
  roleDetail: url + 'selectIparkAdminRoleById',
  roleMenu: url + 'selectIparkAdminMenuById',
  rolePage: url + 'selectIparkAdminRoleByPage',
  selectIparkAdminRoleByList: url + 'selectIparkAdminRoleByList'
}
