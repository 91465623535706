import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/menuSetting',
    name: 'menuSetting',
    component: () => import('@/views/menusetting/Menu.vue')
  },
  {
    path: '/menuSetting/add',
    name: 'menuAdd',
    component: () => import('@/views/menusetting/MenuAdd.vue')
  },
  {
    path: '/menuSetting/update:id',
    name: 'menuUpdate',
    component: () => import('@/views/menusetting/MenuAdd.vue')
  },
  {
    path: '/menuSetting/appAdd',
    name: 'menuAppAdd',
    component: () => import('@/views/menusetting/AppMenuAdd.vue')
  },
  {
    path: '/menuSetting/appUpdate:id',
    name: 'menuAppUpdate',
    component: () => import('@/views/menusetting/AppMenuAdd.vue')
  },
  {
    path: '/menuSetting/config:id',
    name: 'menuConfig',
    component: () => import('@/views/menusetting/MenuConfig.vue')
  }
]

export default router
