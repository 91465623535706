
// 客户名录
export interface Apis {
  customerPage: string;
  customerAdd: string;
  customerUpdate: string;
  customerDelete: string;
  customerDetails: string;
  customerList: string;
  customerActivation: string;
}
const url = '/user/customer/'
export const apis: Apis = {
  // 分页查询
  customerPage: url + 'selectCustomerByPage',
  // 新增
  customerAdd: url + 'insertCustomer',
  // 编辑
  customerUpdate: url + 'updateCustomer',
  // 删除
  customerDelete: url + 'deleteCustomer',
  // 详情
  customerDetails: url + 'selectCustomerByCustomerId',
  // 不分页列表
  customerList: url + 'selectCustomerByList',
  // 激活
  customerActivation: url + 'activationCustomer'
}
