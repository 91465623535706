export interface Apis {
  // 查询资产列表
  selectAssetByPage: string;
  // 查询资产详情
  selectAssetByAssetId: string;
  // 添加资产
  insertAsset: string;
  // 编辑资产经纬度
  updateLocation: string;
  // 删除资产
  deleteAsset: string;
}

export const apis: Apis = {
  selectAssetByPage: '/crud/asset/selectAssetByPage',
  selectAssetByAssetId: '/crud/asset/selectAssetByAssetId',
  insertAsset: '/crud/asset/insertAsset',
  updateLocation: '/crud/asset/updateLocation',
  deleteAsset: '/crud/asset/deleteAsset'
}
