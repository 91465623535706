import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/dicList',
    name: 'dicList',
    component: () => import('@/views/dictionary/List.vue')
  },
  {
    path: '/dicList/add',
    name: 'dicAdd',
    component: () => import('@/views/dictionary/Add.vue')
  },
  {
    path: '/dicList/edit:dicId',
    name: 'dicEdit',
    component: () => import('@/views/dictionary/Add.vue')
  }
]

export default router
