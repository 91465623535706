export interface Apis {
  insertSupplier: string;
  listByPage: string;
  getSupplier: string;
  updateSupplier: string;
  deleteSupplier: string;
  list: string;
  productByPage: string;
  insertProduct: string;
  updateProduct: string;
  getProductById: string;
  delProductById: string;
  communicateByPage: string;
  insertCommunication: string;
  updateCommunication: string;
  deleteCommunication: string;
  getCommunicationById: string;
  // 导入供应商
  importSupplier: string;
}

export const apis: Apis = {
  insertSupplier: '/crud/supplier/insertSupplier',
  listByPage: '/crud/supplier/selectSupplierByPage',
  list: '/crud/supplier/selectSupplierByList',
  getSupplier: '/crud/supplier/selectSupplierBySupplierId',
  updateSupplier: '/crud/supplier/updateSupplier',
  deleteSupplier: '/crud/supplier/deleteSupplier',
  importSupplier: '/crud/supplier/importSupplier',
  productByPage: '/crud/product/selectProductByPage',
  insertProduct: '/crud/product/insertProduct',
  updateProduct: '/crud/product/updateProduct',
  getProductById: '/crud/product/selectProductByProductId',
  delProductById: '/crud/product/deleteProduct',
  communicateByPage: '/crud/communicate/selectCommunicateByPage',
  insertCommunication: '/crud/communicate/insertCommunicate',
  updateCommunication: '/crud/communicate/updateCommunicate',
  deleteCommunication: '/crud/communicate/deleteCommunicate',
  getCommunicationById: '/crud/communicate/selectCommunicateByCommunicateId'
}
