// 客户版本
const url = '/user/version/'

export interface Apis{
  versionPage: string;
  menuList: string;
  versionDetails: string;
  versionDelete: string;
  versionUpdate: string;
  versionAdd: string;
  versionList: string;
  versionRefresh: string;
}

export const apis: Apis = {
  // 分页查询
  versionPage: url + 'selectVersionByPage',
  // 菜单列表
  menuList: url + 'selectIparkMenuById',
  // 详情
  versionDetails: url + 'selectVersionByVersionId',
  // 删除
  versionDelete: url + 'deleteVersion',
  // 编辑
  versionUpdate: url + 'updateVersion',
  // 新增
  versionAdd: url + 'insertVersion',
  // 版本列表
  versionList: url + 'selectVersionByList',
  // 刷新客户数据库
  versionRefresh: url + 'refreshCustomer'
}
