import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/experienceList',
    name: 'experienceList',
    component: () => import('@/views/experienceApply/ExperienceList.vue')
  },
  {
    path: '/experienceList/detail:id',
    name: 'experienceDetail',
    component: () => import('@/views/experienceApply/ExperienceDetail.vue')
  },
  {
    path: '/experienceList/edit:id',
    name: 'experienceEdit',
    component: () => import('@/views/experienceApply/ExperienceEdit.vue')
  },
  {
    path: '/applyList',
    name: 'applyList',
    component: () => import('@/views/experienceApply/ApplyList.vue')
  },
  {
    path: '/applyList/add',
    name: 'applyAdd',
    component: () => import('@/views/experienceApply/ApplyAdd.vue')
  },
  {
    path: '/applyList/edit:id',
    name: 'applyEdit',
    component: () => import('@/views/experienceApply/ApplyAdd.vue')
  },
  {
    path: '/applyList/detail:id',
    name: 'applyDetail',
    component: () => import('@/views/experienceApply/ApplyDetail.vue')
  }
]

export default router
