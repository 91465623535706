export interface Apis{
  // 分页查询
  selectMachineByPage: string;
  // 详情
  selectMachineByManageId: string;
  // 删除
  deleteMachine: string;
  // 编辑
  updateMachine: string;
  // 新增
  insertMachine: string;
}
export const apis: Apis = {
  selectMachineByPage: '/crud/machine/selectMachineByPage',
  selectMachineByManageId: '/crud/machine/selectMachineByManageId',
  deleteMachine: '/crud/machine/deleteMachine',
  updateMachine: '/crud/machine/updateMachine',
  insertMachine: '/crud/machine/insertMachine'

}
