// 字典管理
export interface Apis {
  selectDicByPage: string;
  insertDic: string;
  updateDic: string;
  selectDicByDicId: string;
  synchro: string;
}
const url = '/user/dic/'
export const apis: Apis = {
  // 分页查询
  selectDicByPage: url + 'selectDicByPage',
  // 新增
  insertDic: url + 'insertDic',
  // 编辑
  updateDic: url + 'updateDic',
  // 详情
  selectDicByDicId: url + 'selectDicByDicId',
  // 刷新
  synchro: url + 'synchro'
}
