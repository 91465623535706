// 代理商管理
const url = '/crud/agent/'
export interface Apis {
  /** 新增 */
  insertAgent: string;
  /** 修改 */
  updateAgent: string;
  /** 删除 */
  deleteAgent: string;
  /** 详情 */
  selectAgentByAgentId: string;
  /** 分页查询列表 */
  selectAgentByPage: string;
  /** 不分页查询列表 */
  selectAgentByList: string;
}

export const apis: Apis = {
  insertAgent: url + 'insertAgent',
  updateAgent: url + 'updateAgent',
  deleteAgent: url + 'deleteAgent',
  selectAgentByAgentId: url + 'selectAgentByAgentId',
  selectAgentByPage: url + 'selectAgentByPage',
  selectAgentByList: url + 'selectAgentByList'
}
