import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/expertList',
    name: 'expertList',
    component: () => import('@/views/expert/Expert.vue')
  },
  {
    path: '/expertList/add',
    name: 'expertAdd',
    component: () => import('@/views/expert/ExpertAdd.vue')
  },
  {
    path: '/expertList/update:id',
    name: 'expertUpdate',
    component: () => import('@/views/expert/ExpertAdd.vue')
  },
  {
    path: '/expertList/detail:id',
    name: 'expertDetail',
    component: () => import('@/views/expert/ExpertDetail.vue')
  }
]

export default router
