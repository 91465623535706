export interface Apis {
  videoPage: string;
  videoAdd: string;
  videoUpdate: string;
  videoDelete: string;
  videoDetail: string;
}
export const apis: Apis = {
  videoPage: '/crud/video/selectVideoByPage',
  videoAdd: '/crud/video/insertVideo',
  videoUpdate: '/crud/video/updateVideo',
  videoDelete: '/crud/video/deleteVideo',
  videoDetail: '/crud/video/selectVideoByVideoId'
}
