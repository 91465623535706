import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [

  {
    path: '/deviceCategory',
    name: 'deviceCategoryList',
    component: () => import('@/views/deviceCategory/List.vue')
  },

  {
    path: '/deviceCategory/detail:id',
    name: 'deviceCategoryDetail',
    component: () => import('@/views/deviceCategory/Detail.vue')
  },
  {
    path: '/deviceCategory/edit:id',
    name: 'deviceCategoryEdit',
    component: () => import('@/views/deviceCategory/Edit.vue')
  }
]

export default router
