import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/potion',
    name: 'potionList',
    component: () => import('@/views/potion/potion.vue')
  },
  {
    path: '/potion/add',
    name: 'potionAdd',
    component: () => import('@/views/potion/potionAdd.vue')
  },
  {
    path: '/potion/update:id',
    name: 'potionUpdate',
    component: () => import('@/views/potion/potionAdd.vue')
  },
  {
    path: '/potion/detail:id',
    name: 'potionDetail',
    component: () => import('@/views/potion/potionDetail.vue')
  }
]

export default router
