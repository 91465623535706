import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/trainingVideoList',
    name: 'trainingVideoList',
    component: () => import('@/views/trainingvideo/TrainingVideo.vue')
  },
  {
    path: '/trainingVideoList/add',
    name: 'trainingVideoAdd',
    component: () => import('@/views/trainingvideo/TrainingVideoAdd.vue')
  },
  {
    path: '/trainingVideoList/update:id',
    name: 'trainingVideoUpdate',
    component: () => import('@/views/trainingvideo/TrainingVideoAdd.vue')
  },
  {
    path: '/trainingVideoList/detail:id',
    name: 'trainingVideoDetail',
    component: () => import('@/views/trainingvideo/TrainingVideoDetail.vue')
  }
]
export default router
