export interface Apis{
  insertMedicine: string;
  updateMedicine: string;
  deleteMedicine: string;
  detailDataMedicine: string;
  selectMedicineList: string;
  /** 导入 */
  importMedicine: string;
  /** 药剂推荐状态更新 */
  updateMedicineRecommendStatus: string;
}
export const apis: Apis = {
  insertMedicine: '/crud/medicine/insertMedicine',
  updateMedicine: '/crud/medicine/updateMedicine',
  deleteMedicine: '/crud/medicine/deleteMedicine',
  detailDataMedicine: '/crud/medicine/selectMedicineByMedicineId',
  selectMedicineList: '/crud/medicine/selectMedicineByPage',
  importMedicine: '/crud/medicine/importMedicine',
  updateMedicineRecommendStatus: '/crud/medicine/updateMedicineRecommendStatus'
}
