// 接口名定义
export interface Apis {
  login: string;
  logout: string;
  getVerifyCode: string;
}

export const apis: Apis = {
  login: '/user/login',
  logout: '/user/logout',
  getVerifyCode: '/user/getVerifyCode'
}
