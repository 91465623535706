import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [{
  path: '/agentList',
  name: 'agentList',
  component: () => import('@/views/agent/List.vue')
},
{
  path: '/agentList/add',
  name: 'agentAdd',
  component: () => import('@/views/agent/Add.vue')
},
{
  path: '/agentList/edit:id',
  name: 'agentEdit',
  component: () => import('@/views/agent/Add.vue')
},
{
  path: '/agentList/detail:id',
  name: 'agentDetail',
  component: () => import('@/views/agent/Detail.vue')
}
]

export default router
