import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/assestsManage',
    name: 'assestsManage',
    component: () => import('@/views/assestsManage/List.vue')
  },
  {
    path: '/assestsManage/add',
    name: 'assestsManageAdd',
    component: () => import('@/views/assestsManage/Add.vue')
  },
  {
    path: '/assestsManage/detail/:deviceId',
    name: 'assestsManageDetail',
    component: () => import('@/views/assestsManage/Detail.vue'),
    redirect: '/assestsManage/detail/:deviceId/base',
    children: [
      {
        path: 'base',
        component: () => import('@/views/assestsManage/Base.vue')
      },
      {
        path: 'location',
        component: () => import('@/views/assestsManage/Location.vue')
      },
      {
        path: 'network',
        component: () => import('@/views/device/Network.vue')
      }
    ]
  }
]

export default router
