// 数据处理
interface ObjectType {
  [key: string]: string|number|null;
}
// 去除对象空元素
export const clearObjEmptyPrama = (obj: ObjectType) => {
  for (const key in obj) {
    // 去除空格
    if (typeof obj[key] === 'string') {
      obj[key] = String(obj[key]).trim()
    }
    if (Object.prototype.hasOwnProperty.call(obj, key) && (obj[key] !== 0) && (obj[key] === null || obj[key] === '')) {
      delete obj[key]
    }
  }
  return obj
}
// 获取uuid
export const getUUID = () => {
  // 32长度
  const len = 32
  // 16进制
  const radix = 16
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  for (let i = 0; i < len; i++) {
    uuid[i] = chars[0 | Math.random() * radix]
  }
  return uuid.join('')
}
