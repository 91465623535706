export interface Apis {
  // 七牛上传地址
  uploadQiniup: string;
  getRegion: string;
  upload: string;
  download: string;
  uploadToken: string;
  selectDicByList: string;
  // 导入文件模板
  selectTemplate: string;
  // 供应商分类字典列表新增
  insertDic: string;
  // 供应商分类字典列表修改
  updateDic: string;
  // 字典列表删除
  deleteDic: string;
  // 智库类别字典
  thinktankTypeList: string;
  // 智库标签列表
  thinktankTag: string;
  // 新增智库标签
  insertThinktankTag: string;
  // 新增智库类型字典
  insertThinktankDic: string;
  // 删除智库类型字典
  deleteThinktankDic: string;
  // 修改智库类型字典
  updateThinktankDic: string;
  // 详情智库类型字典
  selectThinktankByDicId: string;
  // 智库类型字典查询 包括子级对象
  selectThinktankDicList: string;
}

export const apis: Apis = {
  uploadQiniup: 'http://upload.qiniup.com/',
  getRegion: '/user/address',
  upload: '/user/file/upload',
  download: '/user/file/download',
  uploadToken: '/user/qiniu/getToken',
  selectDicByList: '/user/dic/selectDicByList',
  selectTemplate: '/user/selectTemplate',
  insertDic: '/user/dic/insertDic/supplierType',
  updateDic: '/user/dic/updateDic/supplierType',
  deleteDic: '/user/dic/deleteDic',
  thinktankTypeList: '/crud/thinktankDic/selectDicByList',
  thinktankTag: '/crud/thinktankTag/selectThinktankTagByList',
  insertThinktankTag: '/crud/thinktankTag/insertThinktankTag',
  insertThinktankDic: '/crud/thinktankDic/insertThinktankDic',
  deleteThinktankDic: '/crud/thinktankDic/deleteThinktankDic',
  updateThinktankDic: '/crud/thinktankDic/updateThinktankDic',
  selectThinktankByDicId: '/crud/thinktankDic/selectThinktankByDicId',
  selectThinktankDicList: '/crud/thinktankDic/selectThinktankDicList'
}
