export interface Apis {
  // 业务标签列表
  selectTenantProductLabelByList: string;
    // 新增标签
    insertTenantProductLabel: string;
    // 编辑标签
    updateTenantProductLabel: string;
    // 删除标签
    deleteTenantProductLabel: string;
  // 体验产品列表
  selectTenantProductByPage: string;
  // 体验产品详情
  selectDetailByProductId: string;
  // 体验产品编辑
  updateTenantProduct: string;

  // 申请列表
  selectTenantApplyByPage: string;
  // 新增申请
  insertTenantApply: string;
  // 编辑申请
  updateTenantApply: string;
  // 申请详情
  selectTenantApplyByApplyId: string;
  // 审核申请
  auditTenantApply: string;
  // 录入申请
  recordTenantApply: string;
  // 根据地区查询客户经理
  selectSm: string;
  // 客户经理列表
  selectSmByList: string;
    // 新增客户经理
    insertSm: string;
    // 编辑客户经理
    updateSm: string;
    // 删除客户经理
    deleteDataSm: string;
  selectCustTProductByPage: string;
}

export const apis: Apis = {
  selectTenantProductLabelByList: '/crud/tenantProductLabel/selectTenantProductLabelByList',
  insertTenantProductLabel: '/crud/tenantProductLabel/insertTenantProductLabel',
  updateTenantProductLabel: '/crud/tenantProductLabel/updateTenantProductLabel',
  deleteTenantProductLabel: '/crud/tenantProductLabel/deleteTenantProductLabel',
  selectTenantProductByPage: '/crud/tenantProduct/selectTenantProductByPage',
  selectDetailByProductId: '/crud/tenantProduct/selectDetailByProductId',
  updateTenantProduct: '/crud/tenantProduct/updateTenantProduct',

  selectTenantApplyByPage: '/crud/tenantApply/selectTenantApplyByPage',
  insertTenantApply: '/crud/tenantApply/insertTenantApply',
  updateTenantApply: '/crud/tenantApply/updateTenantApply',
  selectTenantApplyByApplyId: '/crud/tenantApply/selectTenantApplyByApplyId',
  auditTenantApply: '/crud/tenantApply/auditTenantApply',
  recordTenantApply: '/crud/tenantApply/recordTenantApply',
  selectSmByList: '/crud/tenantSm/selectSmByList',
  selectSm: '/crud/tenantSm/selectSm',
  insertSm: '/crud/tenantSm/insertSm',
  updateSm: '/crud/tenantSm/updateSm',
  deleteDataSm: '/crud/tenantSm/deleteDataSm',
  selectCustTProductByPage: '/crud/tenantProduct/selectCustTProductByPage'
}
