import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/views/supplier/Supplier.vue')
  },
  {
    path: '/supplier/add',
    component: () => import('@/views/supplier/SupplierAdd.vue')
  },
  {
    path: '/supplier/edit',
    component: () => import('@/views/supplier/SupplierAdd.vue')
  },
  {
    path: '/supplier/detail:id',
    props: true,
    component: () => import('@/views/supplier/SupplierDetail.vue'),
    redirect: '/supplier/detail:id/info',
    children: [
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/supplier/SupplierInfo.vue')
      },
      {
        path: 'product',
        name: 'product',
        props: true,
        component: () => import('@/views/supplier/SupplierProduct.vue')
      },
      {
        path: 'product/add',
        component: () => import('@/views/supplier/SupplierProductAdd.vue')
      },
      {
        path: 'product/edit',
        component: () => import('@/views/supplier/SupplierProductAdd.vue')
      },
      {
        path: 'product/detail',
        component: () => import('@/views/supplier/SupplierProductDetail.vue')
      },
      {
        path: 'communicate',
        name: 'communicate',
        props: true,
        component: () => import('@/views/supplier/SupplierCommunicate.vue')
      },
      {
        path: 'communicate/add',
        component: () => import('@/views/supplier/SupplierCommunicateAdd.vue')
      },
      {
        path: 'communicate/edit',
        component: () => import('@/views/supplier/SupplierCommunicateAdd.vue')
      },
      {
        path: 'communicate/detail',
        component: () => import('@/views/supplier/SupplierCommunicateDetail.vue')
      },
      {
        path: 'warehouse',
        name: 'warehouse',
        props: true,
        component: () => import('@/views/supplier/SupplierWarehouse.vue')
      }
    ]
  }
]

export default router
