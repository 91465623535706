
import { Component, Vue } from 'vue-property-decorator'
import Menu from './menu/Index.vue'

@Component({
  components: {
    Menu
  }
})
export default class Layout extends Vue {
  get keepAlive () {
    return this.$store.state.keepAlive
  }

  loginOut () {
    this.$axios.post(this.$apis.users.logout).then(() => {
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('userInfo', '')
      this.$router.push('/login')
      this.$message.success('退出成功')
    })
  }
}
