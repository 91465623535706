export interface Apis {
  expertPage: string;
  expertAdd: string;
  expertUpdate: string;
  expertDelete: string;
  expertDetail: string;
}
export const apis: Apis = {
  expertPage: '/crud/expert/selectExpertByPage',
  expertAdd: '/crud/expert/insertExpert',
  expertUpdate: '/crud/expert/updateExpert',
  expertDelete: '/crud/expert/deleteExpert',
  expertDetail: '/crud/expert/selectExpertByExpertId'
}
