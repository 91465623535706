import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/mechanicsList',
    name: 'mechanicsList',
    component: () => import('@/views/mechanics/Mechanics.vue')
  },
  {
    path: '/mechanicsList/add',
    name: 'mechanicsAdd',
    component: () => import('@/views/mechanics/MechanicsAdd.vue')
  },
  {
    path: '/mechanicsList/update:id',
    name: 'mechanicsUpdate',
    component: () => import('@/views/mechanics/MechanicsAdd.vue')
  },
  {
    path: '/mechanicsList/detail:id',
    name: 'mechanicsDetail',
    component: () => import('@/views/mechanics/MechanicsDetail.vue')
  }
]

export default router
