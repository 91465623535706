import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/plantList',
    name: 'plantList',
    component: () => import('@/views/plant/Plant.vue')
  },
  {
    path: '/plantList/add',
    name: 'plantAdd',
    component: () => import('@/views/plant/PlantAdd.vue')
  },
  {
    path: '/plantList/update:id',
    name: 'plantUpdate',
    component: () => import('@/views/plant/PlantAdd.vue')
  },
  {
    path: '/plantList/detail:id',
    name: 'plantDetail',
    component: () => import('@/views/plant/PlantDetail.vue')
  }
]

export default router
