import { apis as users, Apis as userApi } from '@/api/users'
import { apis as agent, Apis as agentApi } from '@/api/agent'
import { apis as product, Apis as productApi } from '@/api/product'
import { apis as supplier, Apis as supplierApi } from '@/api/supplier'
import { apis as devices, Apis as deviceApi } from '@/api/devices'
import { apis as common, Apis as commonApi } from '@/api/common'
import { apis as userAdmin, Apis as userAdminApi } from '@/api/userAdmin'
import { apis as customer, Apis as customerApi } from '@/api/customer'
import { apis as message, Apis as messageApi } from '@/api/message'
import { apis as messageType, Apis as messageTypeApi } from '@/api/messageType'
import { apis as role, Apis as roleApi } from '@/api/role'
import { apis as menu, Apis as menuApi } from '@/api/menu'
import { apis as crmmenu, Apis as crmmenuApi } from '@/api/crmmenu'
import { apis as version, Apis as versionApi } from '@/api/version'
import { apis as diseases, Apis as diseasesApi } from '@/api/diseases'
import { apis as workGuide, Apis as workGuideApi } from '@/api/workGuide'
import { apis as insectPests, Apis as insectPestsApi } from '@/api/insectPests'
import { apis as information, Apis as informationApi } from '@/api/information'
import { apis as trainingVideo, Apis as trainingVideoApi } from '@/api/trainingVideo'
import { apis as potion, Apis as potionApi } from '@/api/potion'
import { apis as expert, Apis as expertApi } from '@/api/expert'
import { apis as plant, Apis as plantApi } from '@/api/plant'
import { apis as question, Apis as questionApi } from '@/api/question'
import { apis as dictionary, Apis as dictionaryApi } from '@/api/dictionary'
import { apis as deviceCategory, Apis as deviceCategoryApi } from '@/api/deviceCategory'
import { apis as mechanics, Apis as mechanicsApi } from '@/api/mechanics'
import { apis as assestsManage, Apis as assestsManageApi } from '@/api/assestsManage'
import { apis as experienceApply, Apis as experienceApplyApi } from '@/api/experienceApply'

export interface Apis {
  users: userApi;
  agent: agentApi;
  product: productApi;
  supplier: supplierApi;
  devices: deviceApi;
  common: commonApi;
  userAdmin: userAdminApi;
  customer: customerApi;
  message: messageApi;
  messageType: messageTypeApi;
  role: roleApi;
  menu: menuApi;
  crmmenu: crmmenuApi;
  version: versionApi;
  diseases: diseasesApi;
  workGuide: workGuideApi;
  insectPests: insectPestsApi;
  information: informationApi;
  trainingVideo: trainingVideoApi;
  potion: potionApi;
  expert: expertApi;
  plant: plantApi;
  question: questionApi;
  dictionary: dictionaryApi;
  deviceCategory: deviceCategoryApi;
  mechanics: mechanicsApi;
  assestsManage: assestsManageApi;
  experienceApply: experienceApplyApi;
}

export const apis: Apis = {
  users,
  agent,
  product,
  supplier,
  devices,
  common,
  userAdmin,
  customer,
  message,
  messageType,
  role,
  menu,
  crmmenu,
  version,
  diseases,
  workGuide,
  insectPests,
  information,
  trainingVideo,
  expert,
  plant,
  potion,
  question,
  dictionary,
  deviceCategory,
  mechanics,
  assestsManage,
  experienceApply
}
