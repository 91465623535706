// 菜单设置
const url = '/crud/iparkAdminMenu/'
export interface Apis {
  /** 新增菜单 */
  insertIparkAdminMenu: string;
  /** 修改菜单 */
  updateIparkAdminMenu: string;
  /** 禁用、激活菜单 */
  disableIparkAdminMenu: string;
  /** 菜单详情 */
  selectIparkAdminMenuByMenuId: string;
  /** 分页查询菜单列表 */
  selectIparkAdminMenuByPage: string;
  /** 不分页查询菜单列表 */
  selectIparkAdminMenuByList: string;
  /** 查询登录用户菜单列表 */
  menuList: string;
}

export const apis: Apis = {
  insertIparkAdminMenu: url + 'insertIparkAdminMenu',
  updateIparkAdminMenu: url + 'updateIparkAdminMenu',
  disableIparkAdminMenu: url + 'disableIparkAdminMenu',
  selectIparkAdminMenuByMenuId: url + 'selectIparkAdminMenuByMenuId',
  selectIparkAdminMenuByPage: url + 'selectIparkAdminMenuByPage',
  selectIparkAdminMenuByList: url + 'selectIparkAdminMenuByList',
  menuList: url + 'selectIparkAdminMenuByRoleList'
}
