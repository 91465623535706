import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/crmMenuSetting',
    name: 'crmMenuSetting',
    component: () => import('@/views/crmmenusetting/Menu.vue')
  },
  {
    path: '/crmMenuSetting/add',
    name: 'crmMenuAdd',
    component: () => import('@/views/crmmenusetting/MenuAdd.vue')
  },
  {
    path: '/crmMenuSetting/update:id',
    name: 'crmMenuUpdate',
    component: () => import('@/views/crmmenusetting/MenuAdd.vue')
  }
]

export default router
