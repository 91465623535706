import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/workGuideList',
    name: 'workGuideList',
    component: () => import('@/views/workguide/WorkGuide.vue')
  },
  {
    path: '/workGuideList/add',
    name: 'workGuideAdd',
    component: () => import('@/views/workguide/WorkGuideAdd.vue')
  },
  {
    path: '/workGuideList/update:id',
    name: 'workGuideUpdate',
    component: () => import('@/views/workguide/WorkGuideAdd.vue')
  },
  {
    path: '/workGuideList/detail:id',
    name: 'workGuideDetail',
    component: () => import('@/views/workguide/WorkGuideDetail.vue')
  }
]

export default router
