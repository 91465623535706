

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class GlobalDetailHeader extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) url!: string

  back () {
    this.url ? this.$router.push({ path: this.url }) : this.$router.back()
  }
}
