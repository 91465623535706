import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [{
  path: '/productList',
  name: 'productList',
  component: () => import('@/views/product/List.vue')
},
{
  path: '/productList/add',
  name: 'productAdd',
  component: () => import('@/views/product/Add.vue')
},
{
  path: '/productList/export',
  name: 'productExport',
  component: () => import('@/views/product/Export.vue')
},
{
  path: '/productList/result:id',
  name: 'productResult',
  component: () => import('@/views/product/Result.vue')
},
{
  path: '/productList/edit:id',
  name: 'productEdit',
  component: () => import('@/views/product/Add.vue')
},
{
  path: '/productList/price:id',
  name: 'productPrice',
  component: () => import('@/views/product/Price.vue')
},
{
  path: '/productList/detail:id',
  name: 'productDetail',
  component: () => import('@/views/product/Detail.vue'),
  redirect: '/productList/detail:id/detail',
  children: [
    {
      path: 'detail',
      props: true,
      component: () => import('@/views/product/DetailContent.vue')
    },
    {
      path: 'child',
      component: () => import('@/views/product/ChildList.vue')
    }]
},
{
  path: '/productList/child/add:parentId',
  name: 'productChildAdd',
  component: () => import('@/views/product/Add.vue')
},
{
  path: '/productList/child/detail:id',
  name: 'productChildDetail',
  component: () => import('@/views/product/ChildDetail.vue')
},
{
  path: '/productList/baseSet',
  name: 'productBaseSet',
  component: () => import('@/views/product/BaseSet.vue'),
  redirect: '/productList/baseSet/type',
  children: [
    {
      path: 'type',
      props: true,
      component: () => import('@/views/product/TypeList.vue')
    },
    {
      path: 'unit',
      component: () => import('@/views/product/UnitList.vue')
    },
    {
      path: 'attr',
      props: true,
      component: () => import('@/views/product/AttrList.vue')
    },
    {
      path: 'fileType',
      props: true,
      component: () => import('@/views/product/FileTypeList.vue')
    }
  ]
},
// 操作记录
{
  path: '/productList/log',
  name: 'productLogList',
  component: () => import('@/views/product/LogList.vue')
},
// 操作记录
{
  path: '/productList/log/detail',
  name: 'productLogDetail',
  component: () => import('@/views/product/LogDetail.vue')
},
// 产品类型
{
  path: '/productList/baseSet/type/add',
  name: 'productTypeAdd',
  component: () => import('@/views/product/AddType.vue')
},
{
  path: '/productList/baseSet/type/edit:id',
  name: 'productTypeEdit',
  component: () => import('@/views/product/AddType.vue')
},
{
  path: '/productList/baseSet/type/detail:id',
  name: 'productTypeDetail',
  component: () => import('@/views/product/TypeDetail.vue')
},
// 计量单位
{
  path: '/productList/baseSet/unit/add',
  name: 'productUnitAdd',
  component: () => import('@/views/product/AddUnit.vue')
},
{
  path: '/productList/baseSet/unit/edit:id',
  name: 'productUnitEdit',
  component: () => import('@/views/product/AddUnit.vue')
},
// 产品属性
{
  path: '/productList/baseSet/attr/add',
  name: 'productAttrAdd',
  component: () => import('@/views/product/AddAttr.vue')
},
{
  path: '/productList/baseSet/attr/edit:id',
  name: 'productAttrEdit',
  component: () => import('@/views/product/AddAttr.vue')
},
// 文件类型
{
  path: '/productList/baseSet/fileType/add',
  name: 'productFileTypeAdd',
  component: () => import('@/views/product/AddFileType.vue')
},
{
  path: '/productList/baseSet/fileType/edit:id',
  name: 'productFileTypeEdit',
  component: () => import('@/views/product/AddFileType.vue')
}
]

export default router
