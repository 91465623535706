export interface Apis {
  plantPage: string;
  plantAdd: string;
  plantUpdate: string;
  plantDetail: string;
  plantDelete: string;
  plantList: string; // 植物不分页列表
  plantBranchList: string; // 纲目科属种查询
  /** 导入 */
  importPlant: string;
}
export const apis: Apis = {
  plantPage: '/crud/plant/selectPlantByPage',
  plantAdd: '/crud/plant/insertPlant',
  plantUpdate: '/crud/plant/updatePlant',
  plantDetail: '/crud/plant/selectPlantByPlantId',
  plantDelete: '/crud/plant/deletePlant',
  plantList: '/crud/plant/selectPlantByList',
  plantBranchList: '/crud/plant/selectPlantTypeList',
  importPlant: '/crud/plant/importPlant'
}
