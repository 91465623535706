// 用户管理
const url = '/user/iparkUser/'
export interface Apis {
  // 分页查询用户列表
  userPage: string;
  // 不分页查询用户列表
  selectIparkUserByList: string;
  // 新增用户
  userAdd: string;
  // 修改用户
  userUpdate: string;
  // 禁用、启用用户
  userDelete: string;
  // 查询用户详情
  userDetails: string;
  // 重置用户密码
  userResetPassword: string;
}
export const apis: Apis = {
  userPage: url + 'selectIparkUserByPage',
  selectIparkUserByList: url + 'selectIparkUserByList',
  userAdd: url + 'insertIparkUser',
  userUpdate: url + 'updateIparkUser',
  userDelete: url + 'deleteIparkUser',
  userDetails: url + 'selectIparkUserByUserId',
  userResetPassword: url + 'resetPassword'
}
