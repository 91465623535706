import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/insectPests',
    name: 'insectPestsList',
    component: () => import('@/views/insectPests/InsectPests.vue')
  },
  {
    path: '/insectPests/add',
    name: 'insectPestsAdd',
    component: () => import('@/views/insectPests/InsectPestsAdd.vue')
  },
  {
    path: '/insectPests/update:id',
    name: 'insectPestsUpdate',
    component: () => import('@/views/insectPests/InsectPestsAdd.vue')
  },
  {
    path: '/insectPests/detail:id',
    name: 'insectPestsDetail',
    component: () => import('@/views/insectPests/InsectPestsDetail.vue')
  }
]

export default router
