import { RouteConfig } from 'vue-router'

const router: Array<RouteConfig> = [
  {
    path: '/information',
    name: 'informationList',
    component: () => import('@/views/information/information.vue')
  },
  {
    path: '/information/add',
    name: 'informationAdd',
    component: () => import('@/views/information/informationAdd.vue')
  },
  {
    path: '/information/update:id',
    name: 'informationUpdate',
    component: () => import('@/views/information/informationAdd.vue')
  },
  {
    path: '/information/detail:id',
    name: 'informationDetail',
    component: () => import('@/views/information/informationDetail.vue')
  }
]

export default router
