
import { Component, Vue, Watch } from 'vue-property-decorator'
import { MenuItem } from '@/types/crmmenu'

@Component
export default class Menu extends Vue {
  private menuList: MenuItem[] = []

  @Watch('$route')
  routeChange (to: any) {
    this.getButtonList('/' + to.path.split('/')[1], this.menuList)
  }

  @Watch('$store.state.menuList')
  menuListChange (list: MenuItem[]) {
    this.menuList = list
    this.getButtonList('/' + this.$route.path.split('/')[1], list)
    if (list.length > 0 && this.$route.path === '/') {
      const path = list[0].childNode
        ? list[0].childNode[0].url
        : list[0].url
      this.$router.push({
        path: path
      })
    }
  }

  created () {
    if (JSON.parse(localStorage.getItem('menuList') as string)) {
      this.getButtonList('/' + this.$route.path.split('/')[1], (JSON.parse(localStorage.getItem('menuList') as string)))
    }
    this.$store.dispatch('getMenu')
    console.log('menuList:', this.menuList)
  }

  getButtonList (index: string, menuList: Array<MenuItem>) {
    menuList.map((item: MenuItem) => {
      if (item.childNode) {
        this.getButtonList(index, item.childNode)
      } else if (item.url === index) {
        this.$store.commit('updateButtonList', item.button ? item.button : [])
      }
    })
  }

  get active () {
    const path = this.$route.path.split('/')
    return ('/' + (path[1] || ''))
  }
}
